import React, { Component, Fragment } from 'react'
import { Col, Container, Row, Card } from 'react-bootstrap'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Services extends Component {
  render() {

    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: true,
      autoplaySpeed:3000,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      responsive: [
          {
              breakpoint: 1024,
              settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: true,
                  dots: true
              }
          },
          {
              breakpoint: 600,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                  initialSlide: 2
              }
          },
          {
              breakpoint: 480,
              settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
              }
          }
      ]
  };
    return (
      <Fragment>
         <div className="rs-services rs-services-style1">
          <Container>              
              <Row>
              <Slider {...settings}>
               <Col className='p-1' key={1} xl={4} lg={4} md={6} sm={6} xs={12}>
               <div className="services-item rs-animation-hover">
                    	    <div className="services-icon">
                    	    	<i className="fa fa-user"></i>                    	        
                    	    </div>
                    	    <div className="services-desc">
                    	        <h4 className="services-title">Highly Qualified Faculty</h4>
                    	        <p>RostSMU have a team of well experienced and highly qualified faculty members. The faculty encourages and inspires the students to put in their best and excel in their fields.</p>
                    	    </div>
                    	</div>
              </Col>

              <Col className='p-1' key={1} xl={4} lg={4} md={6} sm={6} xs={12}>
              <div className="services-item rs-animation-hover">
                    	    <div className="services-icon">
                    	    	<i className="fa fa-building"></i>                    	        
                    	    </div>
                    	    <div className="services-desc">
                    	        <h4 className="services-title">Largest Campus</h4>
                    	        <p>Rostov State Medical University counts among top-ranked medical universities in Russia. RostSMU has the largest campus among top universities of Russia.</p>
                    	    </div>
                    	</div>
              </Col>

              <Col className='p-1' key={1} xl={4} lg={4} md={6} sm={6} xs={12}>
              <div className="services-item rs-animation-hover">
                    	    <div className="services-icon">
                    	    	<i className="fa fa-graduation-cap"></i>                    	        
                    	    </div>
                    	    <div className="services-desc">
                    	        <h4 className="services-title">Degree Recognized Globally</h4>
                    	        <p>RostSMU degree is recognized globally. Our graduates need to follow the local norms of that particular country, where they are going to work.</p>
                    	    </div>
                    	</div>
              </Col>

                          
              </Slider>

              </Row>
          </Container>

          </div>
          </Fragment>
    )
  }
}

export default Services
